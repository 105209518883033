import { Button } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CellProps } from 'react-table'
import BaseTable from '../../components/BaseTable/BaseTable'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import {
  addOrRemoveEmployeeFromProgram,
  getCompanyEmployees,
  getCurriculumEmployees,
} from './helper'

interface Props extends RouteComponentProps<{ currId: string; id: string }> {}

export default function CompanyCurriculumEmployeePage({ match, history }: Props) {
  const [employees, setEmployees] = useState<any[]>([])
  const [currEmployees, setCurrEmployees] = useState<any[]>([])

  const pageLoad = useCallback(() => {
    if (match.params.id && match.params.currId) {
      getCompanyEmployees(match.params.id).then((res) => {
        setEmployees(res.data)
      })

      getCurriculumEmployees(match.params.currId, match.params.id).then((res) => {
        setCurrEmployees(res.data)
      })
    }
  }, [match.params])

  useEffect(() => {
    // console.log(match.params.id)

    pageLoad()
  }, [pageLoad])

  console.log(currEmployees)

  const columns: any[] = [
    {
      Header: 'Name',
      accessor: 'displayName',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (cellProps: CellProps<any>) => {
        const isAdded = employees.find((x) => currEmployees.find((y) => y.id === x.id))

        return (
          <div className="flex">
            {!isAdded ? (
              <Button
                color="teal"
                onClick={() => {
                  addOrRemoveEmployeeFromProgram(match.params.currId, cellProps.value, true).then(
                    () => {
                      showNotification({
                        message: 'Employee Added',
                      })

                      pageLoad()
                    }
                  )
                }}
              >
                Add
              </Button>
            ) : (
              <Button
                color="red"
                onClick={() => {
                  addOrRemoveEmployeeFromProgram(match.params.currId, cellProps.value, false).then(
                    () => {
                      showNotification({
                        message: 'Employee Removed',
                      })

                      pageLoad()
                    }
                  )
                }}
              >
                Remove
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <DashboardWrapper page="company" title="Curriculum Employees">
      <BaseTable data={employees} columns={columns} />
    </DashboardWrapper>
  )
}
