import React, { useCallback, useEffect, useState } from 'react'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { changePayoutStatus, getCoachPayouts } from './helper'
import BaseTable from '../../components/BaseTable/BaseTable'
import { Select } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'

type Props = {}

export default function CoachPayoutsPage({}: Props) {
  const [data, setData] = useState<any[]>([])
  // console.log('🚀 ~ file: CoachPayoutsPage.tsx:9 ~ CoachPayoutsPage ~ data:', data)

  const pageLoad = useCallback(() => {
    getCoachPayouts().then((res) => {
      setData(res.data)
    })
  }, [])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  const columns: any[] = [
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (props: any) => {
        // console.log(props.row.original)
        const row = props.row.original

        return (
          <Select
            value={props.value}
            // label="Select"
            placeholder="Pick one"
            data={[
              { value: 'unpaid', label: 'Un Paid' },
              { value: 'paid', label: 'Paid' },
            ]}
            onChange={(v) => {
              openConfirmModal({
                title: 'Please confirm',
                labels: { confirm: 'Confirm', cancel: 'Cancel' },
                // onCancel: () => console.log('Cancel'),
                onConfirm: () => {
                  changePayoutStatus(row.id, v || 'unpaid').then(() => {
                    pageLoad()
                    showNotification({
                      message: `Status changed to ${v}`,
                      title: 'Success',
                      color: 'teal',
                    })
                  })
                },
              })
            }}
          />
        )
      },
    },
    {
      Header: 'Total Amount',
      accessor: 'totalAmount',
    },
    {
      Header: 'Coach Name',
      accessor: 'coach.displayName',
    },
    {
      Header: 'Email',
      accessor: 'coach.email',
    },
    // {
    //   Header: 'Action',
    //   accessor: 'id',
    //   Cell: (cellProps: CellProps<any>) => {
    //     return (
    //       <FormControl>
    //         <InputLabel>Select an action</InputLabel>
    //         <Select className="w-40">
    //           <MenuItem disabled>No action</MenuItem>

    //           <MenuItem
    //             value="edit"
    //             onClick={() =>
    //               history.push(`/company/curriculum/${match.params.id}/${cellProps.value}`)
    //             }
    //           >
    //             Edit
    //           </MenuItem>

    //           <MenuItem
    //             value="employees"
    //             onClick={() =>
    //               history.push(
    //                 `/company/curriculum/${match.params.id}/${cellProps.value}/employees`
    //               )
    //             }
    //           >
    //             Employees
    //           </MenuItem>
    //         </Select>
    //       </FormControl>
    //     )
    //   },
    // },
  ]

  return (
    <DashboardWrapper page="coach" title="Coach payouts">
      <BaseTable data={data} columns={columns} />
    </DashboardWrapper>
  )
}
