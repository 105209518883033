import axios, { AxiosError } from 'axios'
import { backendUrl } from './../../env-config'

export const login = async (values: any) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await axios.post(`${backendUrl}/super-admin/auth/login`, {
        values,
      })
      localStorage.setItem('access_token', res.data.data?.token.access_token)
      localStorage.setItem('refresh_token', res.data.data?.token.refresh_token)

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const logout = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')

      resolve({ success: true })
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const refreshAccessToken = async (refreshToken: string) => {
  console.log('refreshing token ', refreshToken)
  return new Promise<string>(async (resolve, reject) => {
    try {
      const defaultAxios = axios.create()
      const res = await defaultAxios.post<ApiResponseI>(
        `${backendUrl}/auth/refresh-access-token`,
        {
          refreshToken,
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      )

      localStorage.setItem('access_token', res.data.data)

      resolve(res.data.data)
    } catch (e: any) {
      const err: AxiosError<ApiResponseI> = e

      localStorage.removeItem('refresh_token')

      reject(err.response?.data.message || err.message)
    }
  })
}
