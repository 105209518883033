import { Button } from '@mantine/core'
import { useCallback, useEffect, useState } from 'react'
import { CellProps } from 'react-table'
import BaseTable from '../../components/BaseTable/BaseTable'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { getCoachToPay } from './helper'
import { RouteComponentProps } from 'react-router-dom'

type Props = {} & RouteComponentProps<{ id: string }>

export default function CoachesPage({ history }: Props) {
  const [data, setData] = useState<any[]>([])
  // console.log('🚀 ~ file: CoachesPage.tsx:12 ~ CoachesPage ~ data:', data)

  const pageLoad = useCallback(() => {
    getCoachToPay().then((res) => {
      setData(res.data)
    })
  }, [])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  const columns: any[] = [
    {
      Header: 'Coach',
      accessor: 'displayName',
    },
    {
      Header: 'To Pay',
      accessor: 'toPay',
    },
    {
      Header: 'Invoice Count',
      accessor: 'invoiceCount',
    },
    // {
    //   Header: 'Last Paid Date',
    //   accessor: 'coach.s',
    // },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <div className="flex gap-2">
            <Button
              color="blue"
              onClick={() => {
                history.push(`/invoice/coaches/${cellProps.value}`)
              }}
            >
              View
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <DashboardWrapper page="coach" title="Coaches">
      <Button
        style={{
          width: 'fit-content',
        }}
        onClick={() => {
          history.push('/invoice/paid')
        }}
      >
        Paid Invoices
      </Button>
      <BaseTable data={data} columns={columns} />
    </DashboardWrapper>
  )
}
