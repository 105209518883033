import { backendUrl } from './../../env-config'
import { authAxios } from './../../helpers/axiosAuthInterceptor'

export const getAllCompanyBudgetRequests = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get(`${backendUrl}/super-admin/get-all-company-budget-requests`)

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const acceptBudgetRequest = async (requestId: string | number) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/super-admin/accept-company-budget-request`, {
        requestId,
      })

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const rejectBudgetRequest = async (requestId: string | number) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/super-admin/reject-company-budget-request`, {
        requestId,
      })

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const getAllCompanies = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get(`${backendUrl}/super-admin/get-all-companies`)

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const getOneCompany = async (companyId: number | string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/super-admin/get-one-company`, {
        companyId,
      })

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const createOrUpdateCompany = async (values: any) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/super-admin/create-or-update-company`, {
        values,
      })

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const getCompanyBudgetRecords = async (compId: string) => {
  const res = await authAxios.post(`${backendUrl}/super-admin/company/get-company-budget-records`, {
    compId,
  })

  return res.data
}

export const addCompanyBudgetRecord = async (compId: string, values: any) => {
  const res = await authAxios.post(`${backendUrl}/super-admin/company/add-budget-record`, {
    compId,
    values,
  })

  return res.data
}

export const getCompanyCoaches = async (compId: string) => {
  const res = await authAxios.post(`${backendUrl}/super-admin/company/get-company-coaches`, {
    compId,
  })

  return res.data
}

export const searchCoaches = async (text: string) => {
  const res = await authAxios.post(`${backendUrl}/super-admin/company/search-coaches`, {
    text,
  })

  return res.data
}

export const addCoachToCompany = async (coachId: number, compId: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/company/add-coach-to-company`, {
    coachId,
    compId,
  })
}

export const removeCoachFromCompany = async (coachId: number, compId: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/company/remove-coach-from-company`, {
    coachId,
    compId,
  })
}

export const getAllCompanyCurriculums = async (compId: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/company/get-all-company-curriculums`, {
    compId,
  })
}

export const getCompanyClients = async (compId: string) => {
  const res = await authAxios.post(`${backendUrl}/super-admin/company/get-company-clients`, {
    compId,
  })

  return res.data
}

export const updateCurriculum = async (data: any) => {
  const res = await authAxios.post(`${backendUrl}/super-admin/company/curriculum/update`, data)

  return res.data
}

export const getACurriculum = async (currId: string) => {
  const res = await authAxios.post(`${backendUrl}/super-admin/company/curriculum/get-one`, {
    currId,
  })

  return res
}

export const searchUser = async (text: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/company/search-user`, {
    text,
  })
}

export const getCompanyEmployees = async (companyId: string) => {
  const res = await authAxios.post(
    `${backendUrl}/super-admin/company/curriculum/get-company-employees`,
    {
      companyId,
    }
  )

  return res
}

export const getCurriculumEmployees = async (currId: string, compId: string) => {
  const res = await authAxios.post(
    `${backendUrl}/super-admin/company/curriculum/get-curriculum-employees`,
    {
      currId,
      compId,
    }
  )

  return res
}

export const addOrRemoveEmployeeFromProgram = async (
  curriculumId: string,
  userId: number,
  isAdd: boolean
) => {
  const res = await authAxios.post(
    `${backendUrl}/super-admin/company/curriculum/add-remove-employee-from-program`,
    {
      curriculumId,
      userId,
      isAdd,
    }
  )

  return res
}
