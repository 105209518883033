import { zodResolver } from '@hookform/resolvers/zod'
import { Button, NumberInput, Select, TextInput, Textarea } from '@mantine/core'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router-dom'
import * as z from 'zod'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { getACurriculum, updateCurriculum } from './helper'

interface Props extends RouteComponentProps<{ currId: string }> {}

const schema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  currentBudget: z.number(),
  minPrice: z.number(),
  maxPrice: z.number(),
  type: z.string(),
})

type SchemaType = z.infer<typeof schema>

export default function CompanyCurriculumEditPage({ match }: Props) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<SchemaType>({
    defaultValues: {},
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    getACurriculum(match.params.currId).then((res) => {
      // console.log({ res }, match.params.currId)
      setValue('id', res.data.id)

      if (res.data.name) {
        setValue('name', res.data.name)
      }

      if (res.data.description) {
        setValue('description', res.data.description)
      }

      if (res.data.maxPrice) {
        setValue('maxPrice', res.data.maxPrice)
      }

      if (res.data.minPrice) {
        setValue('minPrice', res.data.minPrice)
      }

      if (res.data.currentBudget) {
        setValue('currentBudget', res.data.currentBudget)
      }

      if (res.data.type) {
        setValue('type', res.data.type)
      }
    })
  }, [match.params.currId])

  return (
    <DashboardWrapper page="company" title="Category Edit">
      <form
        className="flex flex-col gap-5 p-5"
        onSubmit={handleSubmit((data) => {
          // addCompanyBudgetRecord(compId, data).then(() => {
          //   cb()
          //   close()
          // })
          updateCurriculum(data)

          if (window.confirm('Are you sure?')) {
            updateCurriculum(data)
              .then(() => {
                alert('Success')
              })
              .catch((err) => {
                alert('Error occurred.')
              })
          }

          console.log({ data })
        })}
      >
        <TextInput label="Name" {...register('name')} />
        {/* <TextField label="Name" {...register('name')} variant="outlined" /> */}

        <Textarea label="Description" {...register('description')} />

        {/* <TextField label="Description" {...register('description')} variant="outlined" /> */}

        <Controller
          control={control}
          name="currentBudget"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <NumberInput
              label="Current budget"
              ref={ref}
              value={value}
              onChange={(value) => {
                setValue(name, Number(value))
              }}
              type="number"
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="minPrice"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <NumberInput
              label="Min Price"
              ref={ref}
              value={value}
              onChange={(value) => {
                setValue(name, Number(value))
              }}
              type="number"
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="maxPrice"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <NumberInput
              label="Max Price"
              ref={ref}
              value={value}
              onChange={(value) => {
                setValue(name, Number(value))
              }}
              type="number"
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="type"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <Select
              value={value}
              placeholder="Pick one"
              data={[
                { value: 'required', label: 'Requires' },
                { value: 'discretionary', label: 'Discretionary' },
              ]}
              onChange={(value) => {
                setValue(name, value || '')
              }}
            />
          )}
        />

        <Button type="submit" className="mb-10 self-start">
          Save
        </Button>
      </form>
    </DashboardWrapper>
  )
}
