import { backendUrl } from './../../env-config'
import { authAxios } from './../../helpers/axiosAuthInterceptor'

export const getAllSuperAdmins = async () => {
  return await authAxios.get(`${backendUrl}/super-admin/user/get-all-super-admins`)
}

export const searchUsers = async (text: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/user/search-users`, {
    text,
  })
}

export const makeUserSuperAdmin = async (userId: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/user/make-user-super-admin`, {
    userId,
  })
}

export const removeUserFromSuperAdmin = async (userId: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/user/remove-user-from-super-admin`, {
    userId,
  })
}
