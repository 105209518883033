import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CellProps } from 'react-table'
import Popup from 'reactjs-popup'
import { makeUserSuperAdmin, searchUsers } from '../pages/user/helper'
import BaseTable from './BaseTable/BaseTable'
import { Button, TextInput } from '@mantine/core'

type Props = {
  trigger: JSX.Element
  cb?: () => void
}

export default function AddSuperAdminUserPopup({ trigger, cb }: Props) {
  const [text, setText] = useState('')
  const [users, setUsers] = useState([])
  console.log('🚀 ~ file: AddSuperAdminUserPopup.tsx:22 ~ AddSuperAdminUserPopup ~ users:', users)

  const history = useHistory()

  const columns: any[] = [
    {
      Header: 'Name',
      accessor: 'displayName',
    },

    {
      Header: 'Email',
      accessor: 'email',
    },

    {
      Header: 'Action',
      accessor: 'uuid',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <div>
            <Button
              onClick={() => {
                // addCoachToCompany(cellProps.value, companyId)
                //   .then(() => {
                //     alert('Coach added to the company')
                //   })
                //   .catch((err) => {
                //     // console.log(
                //     //   '🚀 ~ file: AddCompanyCoachPopup.tsx:49 ~ AddCompanyCoachPopup ~ err:',
                //     //   { err }
                //     // )
                //     alert(err.response.data)
                //   })
                if (window.confirm('Are you sure?')) {
                  makeUserSuperAdmin(cellProps.value)
                    .then(() => {
                      cb && cb()
                      alert('Success')
                    })
                    .catch((err) => {
                      // console.log(
                      //   '🚀 ~ file: AddCompanyCoachPopup.tsx:49 ~ AddCompanyCoachPopup ~ err:',
                      //   { err }
                      // )
                      alert(err.response.data)
                    })
                }
              }}
            >
              Make a Super Admin
            </Button>
          </div>
        )
      },
    },
  ]

  // const debouncedHandleInput = debounce((_text) => {
  //   searchCoaches(_text)
  // }, 500)

  return (
    <Popup
      trigger={trigger}
      modal
      onOpen={() => {}}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close: any) => {
        return (
          <div className="flex flex-col gap-5 p-5">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={close}
              className="self-end text-3xl text-red-600 cursor-pointer"
            />

            <div className="flex gap-5 items-end">
              <TextInput
                className="flex-1"
                label="Search"
                value={text}
                onChange={(e) => {
                  // debouncedHandleInput(e.target.value)
                  setText(e.target.value)
                  // searchCoaches(e.target.value).then((res) => {
                  //   setCoaches(res)
                  // })
                }}
              />
              <Button
                type="submit"
                onClick={() => {
                  searchUsers(text).then((res) => {
                    // console.log({ res })
                    setUsers(res.data)
                  })
                }}
              >
                Search
              </Button>
            </div>

            <BaseTable columns={columns} data={users} />
          </div>
        )
      }}
    </Popup>
  )
}
