import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { RouteComponentProps } from 'react-router-dom'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as yup from 'yup'
import { createOrUpdateCompany, getOneCompany } from './helper'
import UserSelect from './components/UserSelect'
import { Button, TextInput } from '@mantine/core'

interface IProps extends RouteComponentProps<{ id: string }> {}

interface IFormProps {
  id?: string | number
  name: string
  description: string
  adminUserId: string
}

const CompanyEditPage: React.FC<IProps> = ({ match, history }) => {
  const [company, setCompany] = useState<any>()
  const isNew = match.params.id === 'new'

  useEffect(() => {
    if (!isNew) {
      getOneCompany(match.params.id).then((res) => {
        setCompany(res.data)
      })
    }
  }, [match.params.id, isNew])

  const initialValues: IFormProps = {
    id: company?.id,
    name: company?.name || '',
    description: company?.description || '',
    adminUserId: '',
  }

  const onSubmit = (values: IFormProps, formikHelpers: FormikHelpers<IFormProps>) => {
    createOrUpdateCompany(values).finally(() => {
      formikHelpers.setSubmitting(false)
      history.push('/company')
    })
  }

  let validationObject: any = {
    name: yup.string().required(),
    description: yup.string(),
  }

  if (isNew) {
    validationObject.adminUserId = yup.string().length(36).required()
  }

  let validationSchema = yup.object(validationObject)

  return (
    <DashboardWrapper page="company" title="Companies">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formikProps: FormikProps<IFormProps>) => {
          console.log(formikProps.values)
          // console.log(formikProps.errors)

          // formikProps.setFieldValue()
          return (
            <Form className="flex flex-col gap-5">
              <Field as={TextInput} name="name" label="Name" required />
              <Field as={TextInput} name="description" label="Description" required />

              {isNew && (
                // <Field
                //   component={TextField}
                //   name="adminUserId"
                //   variant="outlined"
                //   label="Admin User Id (Flex user id)"
                //   placeholder="e.g. 83096c57-f39c-4247-bfaf-1732f26e7682"
                //   color="primary"
                //   required
                // />
                <UserSelect
                  onSelect={(v: string) => {
                    formikProps.setFieldValue('adminUserId', v, true)
                  }}
                />
              )}

              <Button
                type="submit"
                disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
              >
                Save
              </Button>
            </Form>
          )
        }}
      </Formik>
    </DashboardWrapper>
  )
}

export default CompanyEditPage
