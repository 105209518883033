import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Button } from '@mantine/core'
import css from './CoachInvoiceReport.module.css'
import classNames from 'classnames'
import { getOnePaidInvoice } from './helper'

type Props = {}

export default function CoachInvoiceReport({}: Props) {
  const { id } = useParams<{ id: string }>()
  const [data, setData] = useState<any>(null)
  console.log('🚀 ~ file: CoachInvoiceReport.tsx:12 ~ CoachInvoiceReport ~ data:', data)

  useEffect(() => {
    getOnePaidInvoice(id).then(({ data }) => {
      setData(data)
    })
  }, [id])

  // return <p>TODO</p>

  return (
    <div className={css.base}>
      <div className={classNames('flex gap-2 bg-gray-100 p-3', css.printSection)}>
        <Button
          // variant="outlined"
          color="primary"
          onClick={() => {
            window.print()
          }}
        >
          Print
        </Button>
      </div>

      <div className="mt-5">
        <p className="text-4xl">Invoice Report</p>
        <table>
          <tbody>
            <tr>
              <td className="pr-2">Generated At:</td>
              <td>{moment().format('MMMM Do YYYY, h:mm:ss a')}</td>
            </tr>
            <tr>
              <td className="pr-2">Amount:</td>
              <td>Rs {data?.amount}</td>
            </tr>
            <tr>
              <td className="pr-2">Payment Method:</td>
              <td>{data?.paymentMethod}</td>
            </tr>
          </tbody>
        </table>

        <p className="text-2xl mt-5">Curriculum Details</p>

        <table className="p-0 m-0">
          <tbody>
            <tr>
              <td className="pr-2">Name:</td>
              <td>{data?.enrolledCurriculum.curriculum.name}</td>
            </tr>
            <tr>
              <td className="pr-2">Type:</td>
              <td>{data?.enrolledCurriculum.curriculum.type}</td>
            </tr>
            <tr>
              <td className="pr-2">Enrolled At:</td>
              <td>
                {moment(data?.enrolledCurriculum.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
              </td>
              data
            </tr>
          </tbody>
        </table>

        <p className="text-2xl mt-5">Coach</p>

        <table className="p-0 m-0">
          <tbody>
            <tr>
              <td className="pr-2">Name:</td>
              <td>{data?.coach.displayName}</td>
            </tr>
            <tr>
              <td className="pr-2">Email:</td>
              <td>{data?.coach.email}</td>
            </tr>

            {data?.coach.location && (
              <tr>
                <td className="pr-2">Location:</td>
                <td>{data?.coach.location}</td>
              </tr>
            )}

            {data?.coach.phoneNumber && (
              <tr>
                <td className="pr-2">Phone Number:</td>
                <td>{data?.coach.phoneNumber}</td>
              </tr>
            )}

            {data?.coach.isPremium && (
              <tr>
                <td className="pr-2">Premium Coach:</td>
                <td>{data?.coach.isPremium ? 'Yes' : 'No'}</td>
              </tr>
            )}
          </tbody>
        </table>

        <p className="text-2xl mt-5">trainee</p>

        <table className="p-0 m-0">
          <tbody>
            <tr>
              <td className="pr-2">Name:</td>
              <td>{data?.trainee.displayName}</td>
            </tr>
            <tr>
              <td className="pr-2">Email:</td>
              <td>{data?.trainee.email}</td>
            </tr>

            {data?.trainee.location && (
              <tr>
                <td className="pr-2">Location:</td>
                <td>{data?.trainee.location}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
