import React, { useCallback, useEffect, useState } from 'react'
import BaseTable from '../../components/BaseTable/BaseTable'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { acceptBudgetRequest, getAllCompanyBudgetRequests, rejectBudgetRequest } from './helper'
import { CellProps } from 'react-table'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Button } from '@mantine/core'

export default function BudgetRequestPage() {
  const [requests, setRequests] = useState<any[]>([])

  const pageLoad = useCallback(() => {
    getAllCompanyBudgetRequests().then((res) => {
      setRequests(res.data)
    })
  }, [])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  const onAcceptBudget = (id: number) => {
    if (window.confirm('Are you sure you want to ACCEPT the budget to the company?')) {
      acceptBudgetRequest(id).then(() => {
        pageLoad()
        window.alert('Accepted')
      })
    }
  }

  const onRejectBudget = (id: number) => {
    if (window.confirm('Are you sure you want to REJECT the budget to the company?')) {
      rejectBudgetRequest(id).then(() => {
        pageLoad()
        window.alert('Rejected')
      })
    }
  }

  const columns: any[] = [
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (cellProps: CellProps<any>) => {
        let textColor = ''
        switch (cellProps.value) {
          case 'pending':
            textColor = 'text-yellow-500'
            break

          case 'accepted':
            textColor = 'text-green-500'
            break

          case 'rejected':
            textColor = 'text-red-500'
            break

          default:
            break
        }
        return <p className={`uppercase ${textColor}`}>{cellProps.value}</p>
      },
    },
    {
      Header: 'Requested at',
      accessor: 'createdAt',
      Cell: (tableProps: any) => {
        const dateString = moment(tableProps.value).format('dddd, MMMM Do YYYY, h:mm:ss a')
        const fromNow = moment(tableProps.value).fromNow()
        return <p>{dateString}</p>
      },
      disableGlobalFilter: true,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (tableProps: any) => {
        if (tableProps.value && tableProps.value !== 0) {
          return '$' + tableProps?.value?.toLocaleString()
        } else {
          return '$0'
        }
      },
    },
    {
      Header: 'Remark',
      accessor: 'remark',
    },
    {
      Header: 'Requested user',
      accessor: 'requestedUser.displayName',
    },
    {
      Header: 'For company',
      accessor: 'company.name',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <Link className="no-underline" to={`/company/view/${cellProps.row.original.id}`}>
            {cellProps.value}
          </Link>
        )
      },
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <div className="flex gap-2">
            {cellProps.row.original.status === 'pending' ? (
              <>
                <Button onClick={() => onAcceptBudget(cellProps.value)}>Accept</Button>
                <Button color="red" onClick={() => onRejectBudget(cellProps.value)}>
                  Reject
                </Button>
              </>
            ) : (
              'No Action'
            )}
          </div>
        )
      },
    },
  ]

  console.log({ requests })

  return (
    <DashboardWrapper page="budget-request" title="Company budget requests">
      <BaseTable columns={columns} data={requests} />
    </DashboardWrapper>
  )
}
