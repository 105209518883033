import { Select, Text } from '@mantine/core'
import React, { forwardRef, useEffect, useState } from 'react'
import { searchUser } from '../helper'

type Props = {
  onSelect: (id: string) => void
}

interface SelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  email: string
  label: string
  id: string
}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ email, label, id, ...others }: SelectItemProps, ref) => (
    <div ref={ref} {...others}>
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" opacity={0.65}>
          {email}
        </Text>
        <Text size="xs" opacity={0.65}>
          {id}
        </Text>
      </div>
    </div>
  )
)

export default function UserSelect({ onSelect }: Props) {
  const [users, setUsers] = useState<any[]>([])
  // console.log('🚀 ~ file: UserSelect.tsx:9 ~ UserSelect ~ users:', users)
  const [searchValue, onSearchChange] = useState('')

  useEffect(() => {
    if (searchValue) {
      // console.log({ searchValue })

      searchUser(searchValue).then((res) => {
        setUsers(res.data)
      })
    }
  }, [searchValue])

  return (
    <Select
      // size="lg"
      label="Select the company admin"
      placeholder="Pick one"
      searchable
      required
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      itemComponent={SelectItem}
      nothingFound="No options"
      // data={['React', 'Angular', 'Svelte', 'Vue']}
      onChange={(v) => {
        onSelect(v || '')
      }}
      data={users.map((x) => {
        return {
          label: x.displayName,
          value: x.uuid,
          email: x.email,
          id: x.uuid,
        }
      })}
    />
  )
}
