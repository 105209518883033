import { Button } from '@mantine/core'
import { useCallback, useEffect, useState } from 'react'
import { CellProps } from 'react-table'
import BaseTable from '../../components/BaseTable/BaseTable'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { getIndividualCoachPayouts, payCoachInvoices, payOneCoachInvoice } from './helper'
import { RouteComponentProps } from 'react-router-dom'
import { openConfirmModal } from '@mantine/modals'

type Props = {} & RouteComponentProps<{ id: string }>

export default function IndividualCoachInvoicePage({ match, history }: Props) {
  // const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [data, setData] = useState<any[]>([])
  // console.log(
  //   '🚀 ~ file: IndividualCoachInvoicePage.tsx:13 ~ IndividualCoachInvoicePage ~ data:',
  //   selectedItems
  // )

  const pageLoad = useCallback(() => {
    getIndividualCoachPayouts(match.params.id).then((res) => {
      setData(res.data)
    })
  }, [match.params.id])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  const columns: any[] = [
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Total Amount',
      accessor: 'amount',
    },

    // {
    //   Header: 'Last Paid Date',
    //   accessor: 'coach.s',
    // },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <div className="flex gap-2">
            <Button
              color="blue"
              onClick={() => {
                // history.push(`/payouts/coaches/${cellProps.value}`)
                openConfirmModal({
                  title: 'Please confirm',
                  labels: { confirm: 'Confirm', cancel: 'Cancel' },
                  // onCancel: () => console.log('Cancel'),
                  onConfirm: () => {
                    payOneCoachInvoice(cellProps.value).then((res) => {
                      pageLoad()
                    })
                  },
                })
              }}
            >
              Pay
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <DashboardWrapper page="coach" title="Coaches Invoices">
      <div className="flex justify-between">
        <p>Select all the invoices to pay</p>
        <div className="flex items-center gap-4">
          <div className="text-xs">
            <p>Selected Invoices</p>
            <p>${totalAmount}</p>
          </div>
          <Button
            // disabled={}
            onClick={() => {
              openConfirmModal({
                title: 'Please confirm',
                labels: { confirm: 'Confirm', cancel: 'Cancel' },
                // onCancel: () => console.log('Cancel'),
                onConfirm: () => {
                  const ids = ((window as any).selectedRows || []).map((x: any) => x.id)

                  payCoachInvoices(ids).then((res) => {
                    pageLoad()
                  })
                },
              })

              // payCoachInvoices()
              // console.log((window as any).selectedRows)
            }}
          >
            Pay Now
          </Button>
        </div>
      </div>
      <BaseTable
        data={data}
        columns={columns}
        isSelectable
        onSelectRows={(items) => {
          // setSelectedItems(items.map((x) => x.id))
          console.log({ items })
          let total = 0
          for (const item of items) {
            total = total + item.amount
          }
          setTotalAmount(total)
        }}
      />
    </DashboardWrapper>
  )
}
