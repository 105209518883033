import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import BaseTable from '../../components/BaseTable/BaseTable'
import { getAllCompanyCurriculums } from './helper'
import { RouteComponentProps } from 'react-router-dom'
import { CellProps } from 'react-table'
import { Button } from '@mantine/core'

interface Props extends RouteComponentProps<{ id: string }> {}

export default function CompanyCurriculumsPage({ match, history }: Props) {
  const [curriculums, setCurriculums] = useState([])
  useEffect(() => {
    getAllCompanyCurriculums(match.params.id).then((res) => {
      console.log({ res })
      setCurriculums(res.data)
    })
  }, [match.params.id])

  const columns: any[] = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <div className="flex gap-2">
            <Button
              value="edit"
              onClick={() =>
                history.push(`/company/curriculum/${match.params.id}/${cellProps.value}`)
              }
            >
              Edit
            </Button>

            <Button
              value="employees"
              onClick={() =>
                history.push(`/company/curriculum/${match.params.id}/${cellProps.value}/employees`)
              }
            >
              Employees
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <DashboardWrapper page="company" title="Company Curriculums">
      <BaseTable data={curriculums} columns={columns} />
    </DashboardWrapper>
  )
}
