import { Button } from '@mantine/core'
import { useCallback, useEffect, useState } from 'react'
import { CellProps } from 'react-table'
import BaseTable from '../../components/BaseTable/BaseTable'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { getPaidInvoices } from './helper'
import { RouteComponentProps } from 'react-router-dom'

type Props = {} & RouteComponentProps<{ id: string }>

export default function PaidInvoicesPage({ history }: Props) {
  const [data, setData] = useState<any[]>([])
  // console.log('🚀 ~ file: CoachesPage.tsx:12 ~ CoachesPage ~ data:', data)

  const pageLoad = useCallback(() => {
    getPaidInvoices().then((res) => {
      setData(res.data)
    })
  }, [])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  const columns: any[] = [
    {
      Header: 'Coach',
      accessor: 'coach.displayName',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Paid At',
      accessor: 'updatedAt',
    },
    // {
    //   Header: 'Last Paid Date',
    //   accessor: 'coach.s',
    // },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <div className="flex gap-2">
            <Button
              color="blue"
              onClick={() => {
                window.open(
                  `/report/coach-invoice/${cellProps.value}`,
                  'Invoice Report',
                  'width=800,height=800'
                )
              }}
            >
              Print
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <DashboardWrapper page="coach" title="Paid Invoices">
      <BaseTable data={data} columns={columns} />
    </DashboardWrapper>
  )
}
