import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CellProps } from 'react-table'
import Popup from 'reactjs-popup'
import { searchUser } from '../pages/Company/helper'
import BaseTable from './BaseTable/BaseTable'
import { Button, TextInput } from '@mantine/core'

type Props = {
  trigger: JSX.Element
  cb: () => void
  companyId: string
}

export default function AddCompanyEmployeePopup({ trigger, cb, companyId }: Props) {
  const [text, setText] = useState('')
  const [employees, setEmployees] = useState([])
  // console.log('🚀 ~ file: AddCompanyCoachPopup.tsx:20 ~ AddCompanyCoachPopup ~ coaches:', coaches)

  const history = useHistory()

  const columns: any[] = [
    {
      Header: 'Name',
      accessor: 'displayName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },

    {
      Header: 'Action',
      accessor: 'id',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <div>
            <Button
              onClick={() => {
                // addCoachToCompany(cellProps.value, companyId)
                //   .then(() => {
                //     alert('Coach added to the company')
                //   })
                //   .catch((err) => {
                //     // console.log(
                //     //   '🚀 ~ file: AddCompanyCoachPopup.tsx:49 ~ AddCompanyCoachPopup ~ err:',
                //     //   { err }
                //     // )
                //     alert(err.response.data)
                //   })
              }}
            >
              Add to the Company
            </Button>
          </div>
        )
      },
    },
  ]

  // const debouncedHandleInput = debounce((_text) => {
  //   searchCoaches(_text)
  // }, 500)

  return (
    <Popup
      trigger={trigger}
      modal
      onOpen={() => {}}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close: any) => {
        return (
          <div className="flex flex-col gap-5 p-5">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={close}
              className="self-end text-3xl text-red-600 cursor-pointer"
            />

            <TextInput
              label="Search"
              value={text}
              onChange={(e) => {
                // debouncedHandleInput(e.target.value)
                setText(e.target.value)
                searchUser(e.target.value).then((res) => {
                  setEmployees(res.data)
                })
              }}
            />

            <BaseTable columns={columns} data={employees} />
          </div>
        )
      }}
    </Popup>
  )
}
