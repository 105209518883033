import { backendUrl } from './../../env-config'
import { authAxios } from './../../helpers/axiosAuthInterceptor'

export const getCoachPayouts = async () => {
  return await authAxios.get(`${backendUrl}/super-admin/coach/get-all-payouts`)
}

export const getCoachToPay = async () => {
  return await authAxios.get(`${backendUrl}/super-admin/coach/get-all-coaches-to-pay`)
}

export const getIndividualCoachPayouts = async (coachId: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/coach/get-all-coach-payouts`, {
    coachId,
  })
}

export const changePayoutStatus = async (pid: number, status: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/coach/change-payout-status`, {
    pid,
    status,
  })
}

export const payCoachInvoices = async (ids: string[]) => {
  return await authAxios.post(`${backendUrl}/super-admin/coach/pay-coach-invoices`, {
    ids,
  })
}

export const payOneCoachInvoice = async (id: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/coach/pay-one-coach-invoice`, {
    id,
  })
}

export const getPaidInvoices = async () => {
  return await authAxios.get(`${backendUrl}/super-admin/coach/get-paid-invoices`)
}

export const getOnePaidInvoice = async (id: string) => {
  return await authAxios.post(`${backendUrl}/super-admin/coach/get-one-paid-invoice`, {
    id,
  })
}
