import React, { useEffect, useState } from 'react'
import BaseTable from '../../components/BaseTable/BaseTable'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { getAllCompanies } from './helper'
import { Link, RouteComponentProps } from 'react-router-dom'
import { CellProps } from 'react-table'
import { Button, Menu } from '@mantine/core'

interface IProps extends RouteComponentProps {}

const CompaniesPage: React.FC<IProps> = ({ history }) => {
  const [companies, setCompanies] = useState<any[]>([])
  useEffect(() => {
    getAllCompanies().then((res) => {
      setCompanies(res.data)
    })
  }, [])

  const columns: any[] = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <Link className="no-underline" to={`/company/view/${cellProps.row.original.id}`}>
            {cellProps.value}
          </Link>
        )
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Utilized Budget',
      accessor: 'utilized',
      Cell: (tableProps: any) => {
        if (tableProps.value && tableProps.value !== 0) {
          return '$' + tableProps?.value?.toLocaleString()
        } else {
          return '$0'
        }
      },
    },
    {
      Header: 'Remaining Budget',
      accessor: 'currentBudget',
      Cell: (tableProps: any) => {
        if (tableProps.value && tableProps.value !== 0) {
          return '$' + tableProps?.value?.toLocaleString()
        } else {
          return '$0'
        }
      },
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button>Actions</Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => history.push(`/company/edit/${cellProps.value}`)}>
                Edit
              </Menu.Item>

              <Menu.Item onClick={() => history.push(`/company/view/${cellProps.value}`)}>
                View
              </Menu.Item>

              <Menu.Item onClick={() => history.push(`/company/budget/${cellProps.value}`)}>
                Budget
              </Menu.Item>

              <Menu.Item onClick={() => history.push(`/company/coaches/${cellProps.value}`)}>
                Coaches
              </Menu.Item>

              <Menu.Item onClick={() => history.push(`/company/employees/${cellProps.value}`)}>
                Employees
              </Menu.Item>

              <Menu.Item onClick={() => history.push(`/company/curriculum/${cellProps.value}`)}>
                Curriculum
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )
      },
    },
  ]

  return (
    <DashboardWrapper page="company" title="Companies">
      <Button className="self-end" onClick={() => history.push('/company/edit/new')}>
        Create A Company
      </Button>
      <BaseTable columns={columns} data={companies} />
    </DashboardWrapper>
  )
}

export default CompaniesPage
