import { useCallback, useEffect, useState } from 'react'
import { CellProps } from 'react-table'
import AddSuperAdminUserPopup from '../../components/AddSuperAdminUserPopup'
import BaseTable from '../../components/BaseTable/BaseTable'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { getAllSuperAdmins, removeUserFromSuperAdmin } from './helper'
import { Button } from '@mantine/core'

type Props = {}

export default function UsersPage({}: Props) {
  const [users, setUsers] = useState([])

  const loadPage = useCallback(() => {
    getAllSuperAdmins().then((res) => {
      setUsers(res.data)
    })
  }, [])

  useEffect(() => {
    loadPage()
  }, [loadPage])

  const columns: any[] = [
    {
      Header: 'Name',
      accessor: 'attributes.profile.displayName',
    },
    {
      Header: 'Email',
      accessor: 'attributes.email',
    },
    {
      Header: 'Action',
      accessor: 'id.uuid',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <div>
            <Button
              color="red"
              // size="small"
              onClick={() => {
                if (window.confirm('Are you sure?')) {
                  removeUserFromSuperAdmin(cellProps.value)
                    .then(() => {
                      loadPage()
                      alert('Success')
                    })
                    .catch((err) => {
                      // console.log(
                      //   '🚀 ~ file: AddCompanyCoachPopup.tsx:49 ~ AddCompanyCoachPopup ~ err:',
                      //   { err }
                      // )
                      alert(err.response.data)
                    })
                }
              }}
            >
              Remove user from super admin
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <DashboardWrapper page="user" title="Admin Users">
      <AddSuperAdminUserPopup
        cb={() => {
          loadPage()
        }}
        trigger={
          <Button className="self-end" color="primary" type="submit">
            Add super admin user
          </Button>
        }
      />
      <BaseTable data={users} columns={columns} />
    </DashboardWrapper>
  )
}
