import React, { useState } from 'react'
import css from './DashboardWrapper.module.css'
import { Menu as MenuIcon, ChevronLeft, File } from 'react-feather'
import { Menu, MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import LogoImg from '../../assets/viveka-logo.png'
import { logout } from '../../pages/Auth/helpers'
import { Avatar } from '@mantine/core'

interface PropsI extends RouteComponentProps {
  title?: string
  page?: string
}

const DashboardWrapper: React.FC<PropsI> = (props) => {
  // console.log('🚀 ~ file: DashboardWrapper.tsx:3 ~ css:', css)
  const { children, title, page, history } = props

  const [isNavOpen, setIsNavOpen] = useState(false)

  const menuItemStyles = {
    hover: {
      backgroundColor: css.primaryColor,
      color: 'white',
    },
    active: {
      backgroundColor: css.primaryColor,
      color: 'white',
    },
  }

  const onLogoutClick = () => {
    logout()
    history.push('/auth/login')
  }

  return (
    <div className={css.base}>
      <div className={`${css.menu_base} ${isNavOpen && css.menu_open}`}>
        <div className={css.menu_nav_top}>
          <Link to={'/'} className={css.logo}>
            <img alt={'logo'} src={LogoImg} />
          </Link>
          <ChevronLeft className={css.menu_close_button} onClick={() => setIsNavOpen(false)} />
        </div>

        <Link
          to={'/budget-request'}
          className={`${
            page === 'budget-request' ? 'bg-gray-500 text-white' : 'text-gray-600'
          } flex items-center pl-5 h-14 gap-2 cursor-pointer`}
        >
          <File />
          <p className="font-bold">Budget Requests</p>
        </Link>
        <Link
          to={'/company'}
          className={`${
            page === 'company' ? 'bg-gray-500 text-white' : 'text-gray-600'
          } flex items-center pl-5 h-14 gap-2 cursor-pointer`}
        >
          <File />
          <p className="font-bold">Companies</p>
        </Link>
        <Link
          to={'/users'}
          className={`${
            page === 'user' ? 'bg-gray-500 text-white' : 'text-gray-600'
          } flex items-center pl-5 h-14 gap-2 cursor-pointer`}
        >
          <File />
          <p className="font-bold">Users</p>
        </Link>

        <Link
          // to={'/coach/payouts'}
          to={'/invoice/coaches'}
          className={`${
            page === 'coach' ? 'bg-gray-500 text-white' : 'text-gray-600'
          } flex items-center pl-5 h-14 gap-2 cursor-pointer`}
        >
          <File />
          <p className="font-bold">Coach Invoices</p>
        </Link>
      </div>

      <div className={css.content_base}>
        <div className={css.nav_bar}>
          <MenuIcon onClick={() => setIsNavOpen(true)} />
          <p className={css.nav_title}>{title}</p>
          <div className={css.nav_actions}>
            <Menu
              direction="left"
              menuButton={
                <div className={css.avatar_wrapper}>
                  <Avatar size={55}></Avatar>
                </div>
              }
            >
              <MenuItem onClick={() => onLogoutClick()} styles={menuItemStyles}>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className={css.content}>{children}</div>
      </div>
    </div>
  )
}

export default withRouter(DashboardWrapper)
