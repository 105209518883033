import { Redirect } from 'react-router'
import LoginPage from '../pages/Auth/LoginPage'
import BudgetRequestPage from '../pages/Company/BudgetRequestPage'
import CompaniesPage from './../pages/Company/CompaniesPage'
import CompanyEditPage from './../pages/Company/CompanyEditPage'
import CompanyViewPage from './../pages/Company/CompanyViewPage'
import AdjustCompanyBudgetPage from '../pages/Company/AdjustCompanyBudgetPage'
import CompanyCoachesPage from '../pages/Company/CompanyCoachesPage'
import CompanyCurriculumsPage from '../pages/Company/CompanyCurriculumsPage'
import UsersPage from '../pages/user/UsersPage'
import CompanyCurriculumEditPage from '../pages/Company/CompanyCurriculumEditPage'
import CompanyCurriculumEmployeePage from '../pages/Company/CompanyCurriculumEmployeePage'
import CoachPayoutsPage from '../pages/coach/CoachPayoutsPage'
import CompanyEmployeesPage from '../pages/Company/CompanyEmployeesPage'
import CoachesPage from '../pages/coach/CoachesPage'
import IndividualCoachInvoicePage from '../pages/coach/IndividualCoachInvoicePage'
import PaidInvoicesPage from '../pages/coach/PaidInvoicesPage'
import CoachInvoiceReport from '../pages/coach/CoachInvoiceReport'

interface IRoute {
  path: string
  component: any
  authenticated?: boolean
}

const routes: IRoute[] = [
  {
    path: '/',
    component: () => <Redirect to="/budget-request" />,
    authenticated: true,
  },
  {
    path: '/auth/login',
    component: LoginPage,
  },
  {
    path: '/budget-request',
    component: BudgetRequestPage,
    authenticated: true,
  },
  {
    path: '/users',
    component: UsersPage,
    authenticated: true,
  },
  {
    path: '/company',
    component: CompaniesPage,
    authenticated: true,
  },
  {
    path: '/company/edit/:id',
    component: CompanyEditPage,
    authenticated: true,
  },
  {
    path: '/company/view/:id',
    component: CompanyViewPage,
    authenticated: true,
  },
  {
    path: '/company/budget/:id',
    component: AdjustCompanyBudgetPage,
    authenticated: true,
  },
  {
    path: '/company/coaches/:id',
    component: CompanyCoachesPage,
    authenticated: true,
  },
  {
    path: '/company/employees/:id',
    component: CompanyEmployeesPage,
    authenticated: true,
  },
  {
    path: '/company/curriculum/:id',
    component: CompanyCurriculumsPage,
    authenticated: true,
  },
  {
    path: '/company/curriculum/:id/:currId',
    component: CompanyCurriculumEditPage,
    authenticated: true,
  },
  {
    path: '/company/curriculum/:id/:currId/employees',
    component: CompanyCurriculumEmployeePage,
    authenticated: true,
  },
  {
    path: '/coach/payouts',
    component: CoachPayoutsPage,
    authenticated: true,
  },
  {
    path: '/invoice/coaches',
    component: CoachesPage,
    authenticated: true,
  },
  {
    path: '/invoice/coaches/:id',
    component: IndividualCoachInvoicePage,
    authenticated: true,
  },
  {
    path: '/invoice/paid',
    component: PaidInvoicesPage,
    authenticated: true,
  },
  {
    path: '/report/coach-invoice/:id',
    component: CoachInvoiceReport,
    authenticated: true,
  },
]

export default routes
