import React, { useEffect } from 'react'
import css from './BaseTable.module.css'
import {
  Column,
  useSortBy,
  useTable,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  Hooks,
} from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons'
import { Button, Checkbox, TextInput } from '@mantine/core'

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    ;(resolvedRef as any).current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <Checkbox
        ref={resolvedRef}
        {...rest}
        indeterminate={indeterminate}
        styles={{
          input: {
            cursor: 'pointer',
          },
          body: {
            cursor: 'default',
          },
        }}
      />
    </>
  )
})

interface PropsI {
  columns: Column[]
  data: any[]
  loading?: boolean
  initialPageSize?: number
  isSelectable?: boolean
  onSelectRows?: (items: any[]) => void
}

const BaseTable: React.FC<PropsI> = (props) => {
  const { columns, data, loading, initialPageSize, isSelectable, onSelectRows } = props

  // const useButtonStyles = makeStyles({
  //   root: {
  //     marginRight: '10px',
  //     color: '#fff',
  //     fontSize: '20px',
  //     minHeight: '40px',
  //     minWidth: '40px',
  //     borderRadius: '30px',
  //   },
  // })

  // const buttonClasses = useButtonStyles()

  let conditionalHooks = []

  if (isSelectable) {
    conditionalHooks.push(useRowSelect)
    conditionalHooks.push((hooks: Hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            const props = row.getToggleRowSelectedProps()
            return (
              <div>
                <IndeterminateCheckbox {...props} />
              </div>
            )
          },
        },
        ...columns,
      ])
    })
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page,
    prepareRow,
    state,
    setGlobalFilter,

    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: initialPageSize || 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    ...conditionalHooks
  )
  const {
    globalFilter,
    // pageSize,
    // pageIndex
  } = state

  useEffect(() => {
    if (isSelectable) {
      const selectedItems = selectedFlatRows.map((x) => x.original)
      // console.log({ selectedItems })

      onSelectRows && onSelectRows(selectedItems)
      ;(window as any).selectedRows = selectedItems
    }
  }, [isSelectable, onSelectRows, selectedFlatRows])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, position: 'relative' }}>
      <div className={css.actions}>
        <div className={css.pagination}>
          <Button
            style={{ marginLeft: '10px' }}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Button>

          <Button onClick={previousPage} variant="contained" disabled={!canPreviousPage}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>

          {/*<TextField*/}
          {/*  size="small"*/}
          {/*  classes={{*/}
          {/*    root: css.page_count_field,*/}
          {/*  }}*/}
          {/*  variant="outlined"*/}
          {/*  label="Page"*/}
          {/*  color="primary"*/}
          {/*  value={globalFilter || ''}*/}
          {/*  onChange={(event) => setGlobalFilter(event.target.value)}*/}
          {/*/>*/}

          <Button onClick={nextPage} disabled={!canNextPage}>
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>

          <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </Button>
        </div>
        <TextInput
          label="Search"
          className={css.globalFilterInput}
          value={globalFilter || ''}
          onChange={(event) => setGlobalFilter(event.target.value)}
        />
      </div>

      <div className={css.base}>
        {loading && <div className={css.loading}>Loading</div>}

        <table className={css.table} {...getTableProps()}>
          <thead className={css.thead}>
            {headerGroups.map((headerGroup) => (
              <tr className={css.tr} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      className={css.th}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}

                      <span className={css.sort_icon}>
                        {column.canSort &&
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} />
                          ))}
                      </span>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className={css.tbody} {...getTableBodyProps()}>
            {/*{loading && (*/}
            {/*  <tr className={css.loading_icon_tr}>*/}
            {/*    <td>*/}
            {/*      <CircularProgress size={25} />*/}
            {/*    </td>*/}
            {/*  </tr>*/}
            {/*)}*/}
            {!loading && page.length <= 0 && (
              <tr className={css.no_record_tr}>
                <td>No Records</td>
              </tr>
            )}
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr className={css.tr} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={css.td}
                        {...cell.getCellProps()}
                        title={typeof cell.value === 'string' ? cell.value : ''}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BaseTable
