import React, { useCallback, useEffect, useState } from 'react'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { RouteComponentProps } from 'react-router-dom'
import BaseTable from '../../components/BaseTable/BaseTable'
import Popup from 'reactjs-popup'
import CompanyBudgetAdjustmentPopup from '../../components/CompanyBudgetAdjustmentPopup'
import { getCompanyBudgetRecords, getOneCompany } from './helper'
import { Button } from '@mantine/core'

interface IProps extends RouteComponentProps<{ id: string }> {}

const AdjustCompanyBudgetPage: React.FC<IProps> = ({ match, history }) => {
  const [company, setCompany] = useState<any>()
  const [budgetRecords, setBudgetRecords] = useState([])
  console.log('🚀 ~ file: AdjustCompanyBudgetPage.tsx:15 ~ budgetRecords:', budgetRecords)

  const pageLoad = useCallback(() => {
    if (match.params.id) {
      getOneCompany(match.params.id).then((res) => setCompany(res.data))
      getCompanyBudgetRecords(match.params.id).then((data) => {
        setBudgetRecords(data)
      })
    }
  }, [match.params.id])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  const columns: any[] = [
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (props: any) => {
        console.log('🚀 ~ file: AdjustCompanyBudgetPage.tsx:42 ~ props:', props)
        const isAdding = props.row.original.isAdding
        if (isAdding) {
          return <div>${props.value}</div>
        } else {
          return <div>(-${props.value})</div>
        }
      },
    },

    {
      Header: 'Type',
      accessor: 'isAdding',
      Cell: (props: any) => {
        if (props.value) {
          return <div>Increase</div>
        } else {
          return <div>Decrease</div>
        }
      },
    },
    {
      Header: 'Remark',
      accessor: 'remark',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
  ]

  // console.log(company)

  return (
    <DashboardWrapper page="company" title="Company Budget">
      <p className="text-4xl">Current Budget: ${String(company?.currentBudget)}</p>

      <CompanyBudgetAdjustmentPopup
        cb={pageLoad}
        compId={match.params.id}
        trigger={
          <div className="mt-5 self-start">
            <Button>Update Budget</Button>
          </div>
        }
      />
      <BaseTable columns={columns} data={budgetRecords} />
    </DashboardWrapper>
  )
}

export default AdjustCompanyBudgetPage
