import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import Popup from 'reactjs-popup'
import * as z from 'zod'
import { addCompanyBudgetRecord } from '../pages/Company/helper'
import { Button, NumberInput, Select, TextInput } from '@mantine/core'

type Props = {
  trigger: JSX.Element
  compId: string
  cb: () => void
}

const schema = z.object({
  amount: z.number().min(1),
  remark: z.string(),
  type: z.string(),
})

type SchemaType = z.infer<typeof schema>

export default function CompanyBudgetAdjustmentPopup({ trigger, compId, cb }: Props) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<SchemaType>({
    defaultValues: {
      type: 'increase',
    },
    resolver: zodResolver(schema),
  })

  // console.log(watch('amount'))

  return (
    <Popup trigger={trigger} modal closeOnDocumentClick={false} closeOnEscape={false}>
      {(close: any) => {
        return (
          <form
            className="flex flex-col gap-5 p-5"
            onSubmit={handleSubmit((data) => {
              addCompanyBudgetRecord(compId, data).then(() => {
                cb()
                close()
              })
            })}
          >
            <FontAwesomeIcon
              icon={faTimes}
              onClick={close}
              className="self-end text-3xl text-red-600 cursor-pointer"
            />

            <Controller
              control={control}
              name="type"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  ref={ref}
                  value={value}
                  label="Type"
                  onChange={(v) => {
                    setValue('type', v || 'increase')
                    // onChange(v)
                  }}
                  data={[
                    {
                      value: 'increase',
                      label: 'Increase',
                    },
                    {
                      value: 'decrease',
                      label: 'Decrease',
                    },
                  ]}
                />
              )}
            />

            <Controller
              control={control}
              name="amount"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <NumberInput
                  label="Amount"
                  ref={ref}
                  value={value}
                  onChange={(e) => {
                    const v = Number(e)
                    setValue('amount', v)
                  }}
                  error={error?.message}
                />
              )}
            />

            <TextInput label="Remark" {...register('remark')} />

            <Button type="submit" className="mt-5 mb-10">
              Save
            </Button>
          </form>
        )
      }}
    </Popup>
  )
}
