import { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CellProps } from 'react-table'
import AddCompanyCoachPopup from '../../components/AddCompanyCoachPopup'
import BaseTable from '../../components/BaseTable/BaseTable'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { getCompanyCoaches, removeCoachFromCompany } from './helper'
import { Button } from '@mantine/core'

interface Props extends RouteComponentProps<{ id: string }> {}

export default function CompanyCoachesPage({ match }: Props) {
  const [data, setData] = useState<any>()

  const pageLoad = useCallback(() => {
    if (match.params.id) {
      getCompanyCoaches(match.params.id).then((res) => {
        setData(res)
      })
    }
  }, [match.params.id])

  useEffect(() => {
    pageLoad()
  }, [])

  // console.log(data?.coaches)

  const columns: any[] = [
    {
      Header: 'Profile',
      accessor: 'profilePic',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <img src={cellProps.value} alt="Profile Pic" className="w-24 h-24 object-cover rounded" />
        )
      },
    },
    {
      Header: 'Name',
      accessor: 'displayName',
    },

    {
      Header: 'Action',
      accessor: 'id',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <div>
            <Button
              onClick={() => {
                removeCoachFromCompany(cellProps.value, match.params.id)
                  .then(() => {
                    pageLoad()
                    alert('Coach removed from the company')
                  })
                  .catch((err) => {
                    alert(err.response.data)
                  })
              }}
            >
              Remove from the company
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <DashboardWrapper page="company" title="Company Coaches">
      <div className="flex flex-col">
        <AddCompanyCoachPopup
          trigger={
            <Button className="self-start" variant="outlined">
              Add a Coach
            </Button>
          }
          cb={() => {}}
          companyId={match.params.id}
        />
        <BaseTable columns={columns} data={data?.coaches || []} />
      </div>
    </DashboardWrapper>
  )
}
